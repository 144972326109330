import React, { useEffect, useState } from "react";
import "./Store.scss";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";

export const Store = () => {
  const [storeList, setStoreList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(1);

  const HttpCallGet = async (currentPage) => {
    // curl --location 'https://api.amujk.com/api/products'

    return new Promise(async function (resolve, reject) {
      const url = `https://api.amujk.com/api/products?page=${currentPage}`;
      axios({
        method: "GET",
        url: url,
      })
        .then((response) => {
          if (response.status === 403) {
            return resolve(response);
          }
          return resolve(response);
        })
        .catch((err) => {
          return err;
        });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    HttpCallGet(index).then((res) => {
      setStoreList([...storeList, ...res?.data?.data?.data]);
      res?.data?.data?.data.length > 0 && !!res?.data?.data?.next_page_url
        ? setHasMore(true)
        : setHasMore(false);
      setIsLoading(false);
    });
    setIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <InfiniteScroll
      dataLength={storeList?.length} //This is important field to render the next data
      next={fetchData}
      hasMore={hasMore}
    >
      <div className="store">
        <div className="container store-container">
          {storeList.length > 0 && (
            <ul className="store-list list-unstyled">
              {storeList?.map((item, index) => {
                return (
                  <li className="" key={index}>
                    <StoreCard store={item} />
                  </li>
                );
              })}
            </ul>
          )}
          {isLoading && (
            <div className="loading text-center">
              <Spinner animation="border" role="status" />
            </div>
          )}
        </div>
      </div>
    </InfiniteScroll>
  );
};

const StoreCard = ({ store }) => {
  const { t } = useTranslation();
  return (
    <div className="store-card gold-border">
      <div className="store-card-img">
        <img src={store.image} alt="" className="img-fluid" />
      </div>
      <div className="store-card-content">
        <h2 className="store-card-tilte gold-text">{store.title}</h2>
        <div className="mb-1">
          <strong className="gold-text">Weight:</strong>
          <span>{` ${store.grams} gm`}</span>
        </div>
        <div className="mb-1">
          <strong className="gold-text">Carat:</strong>{" "}
          <span>
            {store.carat} {t("carat")}
          </span>
        </div>
        <p>{store.description}</p>
        <div className="store-card-cost gold-text">
          {Number(store?.price).toFixed(3)}
          {t("kwd")}
        </div>
      </div>
    </div>
  );
};
