import React, { Suspense } from "react";
import "./App.scss";
import { DefaultLayout } from "./DefaultLayout/index";
import { Home, Store } from "./Pages";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Suspense fallback="loading">
        <DefaultLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/store" element={<Store />} />
          </Routes>
        </DefaultLayout>
      </Suspense>
    </>
  );
}

export default App;
