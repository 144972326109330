import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./defaultLayout.scss";

export const DefaultLayout = ({ children }) => {
  return (
    <main>
      <Header />
      {children}
      <Footer />
    </main>
  );
};
