import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import logo from "../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const Header = (args) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const [lang, setLang] = useState();
  const lngs = [
    { value: "en", text: "En" },
    { value: "ar", text: "عربي" },
  ];
  const [isToggle, setIsToggle] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsToggle(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    setLang("en");
    i18n.changeLanguage("en");
  }, []);
  const handleChange = (e) => {
    setLang(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <header>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div ref={menuRef} className="header-right">
            <div className="hamburgar">
              <div
                className="hamburgar-content"
                onClick={() => setIsToggle((toggle) => !toggle)}
              >
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={`menu ${isToggle ? "show" : ""}`}>
              <ul className="menu-list list-unstyled">
                <li>
                  <Link
                    to="/"
                    alt="xcv"
                    className={`link ${
                      location.pathname === "/" ? "gold-text" : ""
                    }`}
                    onClick={() => setIsToggle(false)}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/store"
                    alt="xcv"
                    className={`link ${
                      location.pathname === "/store" ? "gold-text" : ""
                    }`}
                    onClick={() => setIsToggle(false)}
                  >
                    Store
                  </Link>
                </li>
                <li>
                  <Form.Select
                    className="language gold-border"
                    value={lang}
                    onChange={handleChange}
                  >
                    {lngs.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.text}
                        </option>
                      );
                    })}
                  </Form.Select>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
