import React from "react";
import { useTranslation } from "react-i18next";
import location from "../assets/images/location.svg";
import whatsapp from "../assets/images/whatsapp.svg";
import mail from "../assets/images/mail.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer">
        <div className="container">
          <div className="d-flex align-items-start justify-content-between flex-wrap gap-4">
            <div className="d-flex flex-column">
              <div className="footer_logo">
                <strong className="logoNme">{t("brand_name")}</strong>
              </div>
              <div className="address">
                <div className="icon">
                  <img src={location} alt="" />
                </div>
                Shop no. 138, Souk-Al-Wataniya
                <br /> Kuwait City, KUWAIT{" "}
              </div>
            </div>

            <div className="d-flex flex-column">
              <ul className="list-unstyled mb-0">
                <li className="footerTitle">{t("contact")}</li>
                <li>
                  <span className="icon">
                    <img src={whatsapp} alt="" />
                  </span>
                  <a
                    href="tel:+96560980453"
                    target="_blank"
                    alt="+96560980453"
                    rel="noreferrer"
                  >
                    +96560980453
                  </a>
                </li>
                <li>
                  <span className="icon">
                    <img src={mail} alt="" />
                  </span>
                  <a
                    href="mailto:arbashgulf@yahoo.com"
                    target="_blank"
                    alt="arbashgulf@yahoo.com"
                    rel="noreferrer"
                  >
                    arbashgulf@yahoo.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="text-copyright">{t('cp')} 2020 - xyz.com</div> */}
      </div>
    </footer>
  );
};

export default Footer;
