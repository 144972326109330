import React, { useEffect, useState } from "react";
import "./goldRate.scss";
import axios from "axios";
import { useTranslation } from "react-i18next";

import dollar from "../../assets/images/dollar-circle.svg";
import clock from "../../assets/images/clock.svg";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import tiktok from "../../assets/images/tiktok.svg";

export const Home = () => {
  const current = new Date();
  const [dateState, setDateState] = useState(new Date());
  const [gData, setdata] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 1000);
  }, []);

  useEffect(() => {
    HttpCallGet(`get_current_rate`).then((res) => {
      setdata(res?.data);
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      HttpCallGet(`get_current_rate`).then((res) => {
        setdata(res?.data);
      });
    }, 30000);
  }, []);

  const HttpCallGet = async (method) => {
    return new Promise(async function (resolve, reject) {
      const url = "https://api.amujk.com/api/" + method;
      axios({
        method: "GET",
        url: url,
      })
        .then((response) => {
          if (response.status === 403) {
            return resolve(response);
          }
          return resolve(response);
        })
        .catch((err) => {
          return err;
        });
    });
  };

  return (
    <div className="home">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center gold-status-container">
          <div className="status-card gold-border">
            <img src={dollar} alt="" />
            <span className="gold-content">
              <strong>
                {gData?.goldRate} {t("usd")}
              </strong>
              <small dir={i18n.dir()}>
                {t("usd")} {t("gold_rate")}
              </small>
            </span>
          </div>

          <div className="social-link d-flex">
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=100093314416926&mibextid=LQQJ4d"
                className=" gold-border"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="" />
              </a>
            </div>
            <div>
              <a
                href="https://www.instagram.com/almohammediunited?igsh=NWVpbDR3emJ3bGN4&utm_source=qr"
                className=" gold-border"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="" />
              </a>
            </div>
            <div>
              <a
                href="https://wa.me/qr/E4QWYYMULTYHN1"
                className=" gold-border"
                target="_blank"
                rel="noreferrer"
              >
                <img src={whatsapp} alt="" />
              </a>
            </div>
            <div>
              <a
                href="https://www.tiktok.com/@almohammediunited?_t=8ji1W5lFVg2&_r=1"
                className=" gold-border"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktok} alt="tiktok icon" />
              </a>
            </div>
          </div>

          <div className="status-card gold-border">
            <img src={clock} alt="" />
            <span className="current-time">
              <strong>
                {dateState.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                })}
              </strong>
              <small>
                {new Intl.DateTimeFormat("en-GB", {
                  weekday: "long",
                  month: "long",
                  day: "2-digit",
                }).format(current)}
              </small>
            </span>
          </div>
        </div>
        <div className="table-home">
          <table className="currentRate" cellPadding="0" cellSpacing="0">
            <thead>
              <tr className="table-bg">
                <th className="gold-border">
                  <span className="gold-text">{t("carat-label")}</span>
                </th>
                <th className="gold-border">
                  <span className="gold-text">{t("Buying")}</span>
                </th>
                <th className="gold-border">
                  <span className="gold-text">{t("selling")}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {gData?.data?.map((item, i) => {
                return [
                  <tr key={i} className="table-bg">
                    <td className="gold-border">
                      <span className="gold-text">
                        {item?.carat} {t("carat")}
                      </span>
                    </td>
                    <td className="gold-border">
                      <span>
                        {item?.buyPrice.toFixed(3)} {t("kwd")}
                      </span>
                    </td>
                    <td className="gold-border">
                      <span>
                        {item?.sellPrice.toFixed(3)} {t("kwd")}
                      </span>
                    </td>
                  </tr>,
                ];
              })}
            </tbody>
          </table>
        </div>
        {/* <p className="text">
          <span>{t("rate_link")}</span>
        </p> */}
      </div>
    </div>
  );
};
